/* eslint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* eslint-enable */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-weight: 600;
}

@keyframes toast-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.animate-toast-slide {
  animation: toast-slide 0.3s ease-out forwards;
}

.rich-text-content {
  /* Base styles */
  word-wrap: break-word;
  overflow-wrap: break-word;

  /* Headers */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  /* Lists */
  ul,
  ol {
    padding-left: 1.5em;
    margin: 0.5em 0;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  /* Links */
  a {
    color: #3b82f6;
    text-decoration: underline;
  }

  /* Block quotes */
  blockquote {
    border-left: 3px solid #e5e7eb;
    padding-left: 1em;
    margin: 1em 0;
    color: #6b7280;
  }

  /* Inline text styling */
  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  /* Spacing */
  p {
    margin-bottom: 0.5em;
  }

  /* Line height for better readability */
  line-height: 1.5;
}

/* Quill editor styles */
.ql-editor {
  min-height: 200px;
  font-size: 0.875rem;
}

.ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background-color: #f9fafb;
  border-color: #e5e7eb;
}

.ql-toolbar button:hover {
  color: #3b82f6;
}

.ql-toolbar button.ql-active {
  color: #3b82f6;
}

.ql-formats {
  margin-right: 12px !important;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

/* Custom scrollbar for the kanban columns */
.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #999;
}